//
// Toastr
//
$prefix: bs- !default;
$border-radius: 0.475rem !default;
$dropdown-border-radius: $border-radius !default;

$font-weight-normal: 400 !default;
$font-weight-semibold: 500 !default;



@mixin svg-bg-icon($type, $color: none, $update: false) {
  $bg-image: '';

  // Icon type;
  @if ($type == close) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
  }

  @if ($type == check) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'><path fill='#{$color}' d='M4.89557 6.49823L2.79487 4.26513C2.26967 3.70683 1.38251 3.70683 0.857309 4.26513C0.375593 4.77721 0.375593 5.57574 0.857309 6.08781L4.74989 10.2257C5.14476 10.6455 5.81176 10.6455 6.20663 10.2257L13.1427 2.85252C13.6244 2.34044 13.6244 1.54191 13.1427 1.02984C12.6175 0.471537 11.7303 0.471536 11.2051 1.02984L6.06096 6.49823C5.74506 6.83403 5.21146 6.83403 4.89557 6.49823Z'/></svg>");
  }

  @if ($type == arrow-top) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='#{$color}'><path d='M3.23571 2.72011L4.97917 4.46358C5.15176 4.63618 5.43158 4.63617 5.60417 4.46358C5.77676 4.29099 5.77676 4.01118 5.60417 3.83861L3.29463 1.52904C3.13192 1.36629 2.86809 1.36629 2.70538 1.52904L0.395812 3.83861C0.22325 4.01117 0.22325 4.29099 0.395812 4.46358C0.568437 4.63617 0.84825 4.63617 1.02081 4.46358L2.76429 2.72011C2.89446 2.58994 3.10554 2.58994 3.23571 2.72011Z'/></svg>");
  }

  @if ($type == arrow-bottom) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='#{$color}'><path d='M2.76429 3.27989L1.02083 1.53642C0.848244 1.36382 0.568419 1.36383 0.395831 1.53642C0.223244 1.70901 0.223244 1.98882 0.395831 2.16139L2.70537 4.47096C2.86808 4.63371 3.13191 4.63371 3.29462 4.47096L5.60419 2.16139C5.77675 1.98883 5.77675 1.70901 5.60419 1.53642C5.43156 1.36383 5.15175 1.36383 4.97919 1.53642L3.23571 3.27989C3.10554 3.41006 2.89446 3.41006 2.76429 3.27989Z'/></svg>");
  }

  @if ($type == arrow-start) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='#{$color}'><path d='M2.72011 2.76429L4.46358 1.02083C4.63618 0.848244 4.63617 0.568419 4.46358 0.395831C4.29099 0.223244 4.01118 0.223244 3.83861 0.395831L1.52904 2.70537C1.36629 2.86808 1.36629 3.13191 1.52904 3.29462L3.83861 5.60419C4.01117 5.77675 4.29099 5.77675 4.46358 5.60419C4.63617 5.43156 4.63617 5.15175 4.46358 4.97919L2.72011 3.23571C2.58994 3.10554 2.58994 2.89446 2.72011 2.76429Z'/></svg>");
  }

  @if ($type == arrow-end) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='#{$color}'><path d='M3.27989 3.23571L1.53642 4.97917C1.36382 5.15176 1.36383 5.43158 1.53642 5.60417C1.70901 5.77676 1.98882 5.77676 2.16139 5.60417L4.47096 3.29463C4.63371 3.13192 4.63371 2.86809 4.47096 2.70538L2.16139 0.395812C1.98883 0.22325 1.70901 0.22325 1.53642 0.395812C1.36383 0.568437 1.36383 0.84825 1.53642 1.02081L3.27989 2.76429C3.41006 2.89446 3.41006 3.10554 3.27989 3.23571Z'/></svg>");
  }

  @if ($type == sort) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='#{$color}'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/></svg>");
  }

  @if ($type == sort-asc) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='#{$color}'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/></svg>");
  }

  @if ($type == sort-desc) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='#{$color}'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/></svg>");
  }

  @if ($type == expand) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'><rect opacity='0.3' x='2' y='2' width='20' height='20' rx='5' fill='#{$color}'/><rect x='10.8891' y='17.8033' width='12' height='2' rx='1' transform='rotate(-90 10.8891 17.8033)' fill='#{$color}'/><rect x='6.01041' y='10.9247' width='12' height='2' rx='1' fill='#{$color}'/></svg>");
  }

  @if ($type == collapse) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'><rect opacity='0.3' x='2' y='2' width='20' height='20' rx='5' fill='#{$color}'/><rect x='6.0104' y='10.9247' width='12' height='2' rx='1' fill='#{$color}'/></svg>");
  }

  // Icon style
  @if $update == false {
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
  }

  @if $color != none {
    background-color: $color;
  }

  -webkit-mask-image: escape-svg($bg-image);
  mask-image: escape-svg($bg-image);
}


// Base
.toast {
  // this is for RTL
  background-position: calc(100% - 1.5rem) center !important;
  /*rtl:ignore*/
  background-position: 1.5rem center !important;
  box-shadow: var(--#{$prefix}dropdown-box-shadow) !important;
  border-radius: $dropdown-border-radius !important;
  border: 0 !important;
  background-color: var(--#{$prefix}gray-100);
  color: var(--#{$prefix}gray-700);
  padding: 1.25rem 1.25rem 1.25rem 4.5rem !important;

  .toast-close-button {
    outline: none !important;
    font-size: 0;
    width: 0.85rem;
    height: 0.85rem;
  }

  // Title
  .toast-title {
    font-size: 1.15rem;
    font-weight: $font-weight-semibold;

    & + .toast-message {
      margin-top: 0.25rem;
    }
  }

  // Message
  .toast-message {
    font-size: 1rem;
    font-weight: $font-weight-normal;
  }

  // States
  &.toast-success {
    background-color: var(--#{$prefix}success);
    color: var(--#{$prefix}success-inverse);

    .toast-close-button {
      @include svg-bg-icon(close, var(--#{$prefix}success-inverse));
    }
  }

  &.toast-info {
    background-color: var(--#{$prefix}info);
    color: var(--#{$prefix}info-inverse);

    .toast-close-button {
      @include svg-bg-icon(close, var(--#{$prefix}info-inverse));
    }
  }

  &.toast-warning {
    background-color: var(--#{$prefix}warning);
    color: var(--#{$prefix}warning-inverse);

    .toast-close-button {
      @include svg-bg-icon(close, var(--#{$prefix}warning-inverse));
    }
  }

  &.toast-error {
    background-color: var(--#{$prefix}danger);
    color: var(--#{$prefix}danger-inverse);

    .toast-close-button {
      @include svg-bg-icon(close, var(--#{$prefix}danger-inverse));
    }
  }
}

// Placements
.toast-top-center {
  top: 12px;
}

.toast-bottom-center {
  bottom: 12px;
}
