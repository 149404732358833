.product-details-modal-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Main image up top */
  .product-main-image {
    margin-bottom: 1rem;
  }
  
  /* Thumbnails in a row */
  .product-thumbnails {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  /* The grid for key-value pairs */
  .product-data-grid {
    display: grid;
    grid-template-columns: 1fr 2fr; /* label : value ratio */
    row-gap: 0.5rem;
    column-gap: 0rem;
    margin-top: 1rem;
  }
  
  .product-data-row {
    display: contents; /* so label/value occupy the assigned columns */
  }

  
  
  .product-data-label {
    font-weight: bold;
    text-transform: capitalize;
    border-bottom:1px solid #333333;
    padding:5px;
  }
  
  .product-data-value {
    white-space: pre-wrap;
    border-bottom:1px solid #333333;
    padding:5px;
  }


