/* \src\app\pages\dashboard\Apps\AmazonMAPViolationsWrapper.css */
.product-name , .card .card-body span.product-name {
    display: -webkit-box; line-clamp: 2;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    -webkit-box-orient: vertical; 
    overflow: hidden; max-width: 200px;
    text-overflow: ellipsis; white-space: wrap;
    word-wrap: break-word; line-height: 1.1 !important;
    font-size: 12px; 
  }
  
/* Progress Bullet Styling */
.card-body .progress-bullets .progress-bullet+.step-text {
 line-height: 1.3 !important; 
 font-size: 10px !important; 
}

/* Adjust the color and size of the icons inside the progress bullets */
.progress-bullet .pi {
  font-size: 1rem;
}

/* Product Name Truncation */
.product-name {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Table Styling */
.data-table-container {
  width: 100%; max-height: 350px; 
  overflow-y: auto; scrollbar-color: var(--bs-primary) var(--bs-gray-200);
}

.table th,
.table td {
  vertical-align: middle;
}

.violations-table-container {
  max-height: 450px;
  overflow-y: auto;
  scrollbar-color: var(--bs-primary) var(--bs-gray-200);
}

/* Scrollbar Styling (Optional for better aesthetics) */
.data-table-container::-webkit-scrollbar,
.violations-table-container::-webkit-scrollbar {
  width: 8px;
}


.table-product-data tbody tr {
  cursor: pointer;
}
.table-product-data .form-check-input {
  width: 20px; height: 20px; padding: 4px; 
}
.select-all-label label {
  font-size: 12px; margin-left: 8px; color: white; 
  display: inline-flex; cursor: pointer; 
}
.table-product-data .product-name-td , .table-product-data .checkbox-td {
  max-width: max-content; width: max-content;
}
.fit-content {
  width: 150px; max-width: fit-content;
}
.table-product-data table th:not(.w-m-120):not(.fit-content) {
  width: 250px; max-width: max-content;
}
.w-m-120 {
  width: 120px; max-width: max-content;
}
.violations-table-container table th:not(.violating-sellers) {
  max-width: max-content; width: auto; 
}
.violating-sellers {
  min-width: 320px; 
}
.summary-report-container .card {
  min-height: 105px; text-align: center;
  width: 20%; flex: 1 1 20%; max-width: calc(20% - 0.75rem);
}
.summary-report-container .card .card-text , .summary-report-container .card .card-title {
  margin-bottom: 0; 
}
.summary-report-container .card .card-body {
  display: flex; flex-direction: column; justify-content: center;
}

@media only screen and (max-width: 1000px){
  .summary-report-container .card {
    width: 50%; flex: 1 1 50%; max-width: calc(50% - 0.75rem);
  }
}