/* src/app/pages/dashboard/Apps/ProductAnalysisWrapper.tsx */

.main-p-datatable-table .p-datatable {
  border-radius: 10px;
  background-color: #2c3e50;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

/* Table headers */
.main-p-datatable-table .p-datatable-thead > tr > th {
  background-color: #34495e;
  color: #ecf0f1;
  font-weight: bold;
  text-align: left;
}

/* Zebra striping for rows */
.main-p-datatable-table .p-datatable-tbody > tr:nth-child(3n) {
  border-bottom: 1px solid black;
}
.main-p-datatable-table .p-datatable-table .p-datatable-tbody > tr {
  background: #1e1e1e !important;
}
.main-p-datatable-table .p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #162331 !important;
}
/* Hover style for rows in the main datatable */
.main-p-datatable-table .p-datatable-tbody > tr:hover {
  background-color: #1d354f !important;
  cursor: pointer;
}

/* Optionally, add some padding or border styles for expansion rows */
.main-p-datatable-table .p-datatable-tbody > tr {
  transition: background-color 0.2s ease;
}
.info-app-wrapper .p-progress-spinner {
  width: 45px;
  height: 350px;
}
.p-dropdown-items-wrapper .p-dropdown-items {
  padding-left: 8px;
  padding-right: 8px;
}
.p-dropdown-items-wrapper .p-dropdown-items li {
  font-size: 10px;
}
.p-dropdown-items-wrapper .p-dropdown-items li:first-child {
  display: none;
}

.progress-steps-wrapper .progress-steps {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  margin-top: 20px;
  max-height: 100%;
  overflow-y: scroll;
}
.progress-steps-wrapper {
  overflow: hidden;
  max-height: 150px;
  overflow: hidden;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}
.progress-steps-wrapper.progress-steps li {
  min-width: 33%;
  max-width: 50%;
  width: max-content;
  font-size: 11px;
  margin-bottom: 5px;
}
.progress-steps-wrapper .progress-steps li i + span {
  padding-right: 10px;
}
/* Customize the tooltip content */
.p-tooltip .p-tooltip-text {
  color: #ecf0f1 !important;
  background-color: #34495e !important;
  border: 1px solid #2c3e50 !important;
}

.actions-wrapper .p-dropdown,
.actions-wrapper button {
  width: stretch;
  max-width: calc(50% - 1rem);
}
.info-app-container .p-inputtext,
.info-app-container .p-dropdown {
  width: unset;
}
.actions-wrapper-container {
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.actions-wrapper-container > div {
  max-width: calc(100% - 275px - 0.5rem);
}
.actions-wrapper-container > div.actions-wrapper {
  max-width: calc(275px - 0.5rem);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 100%;
  flex-grow: 1;
}
