/* RepricerApp.css */

/* Ensure text is readable */
.custom-datatable .p-datatable-tbody > tr > td,
.custom-datatable .p-datatable-thead > tr > th {
  font-size: 13px; font-weight: normal;
}
.custom-datatable .p-datatable-tbody tr td {
  max-width: 140px;
}
.custom-datatable .p-datatable-thead > tr > th {
  font-size: 13px; padding-left: 8px; padding-right: 8px; 
  max-width: max-content;
  text-align: center;
}
.custom-datatable .p-datatable-thead > tr > th  .p-column-header-content {
  width: max-content; font-weight: 500;
}
.custom-datatable .p-datatable-thead > tr > th svg {
  width: 10px; 
}
.custom-datatable .p-datatable-thead > tr > th .p-column-title {
  line-height: 1.2 !important; 
}
/* Style for the Update Repricer button */
.update-button {
  background-color: #0F8FE0;
  color: #FFFFFF;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}

.update-button:hover {
  background-color: #0C7BC2;
}


/* Green color for positive changes */
.positive-change {
  color: #28a745; /* Soft green */
}

/* Red color for negative changes */
.negative-change {
  color: #dc3545; /* Soft red */
}

/* White color for no change or new entries */
.no-change, .new-entry {
  color: #ffffff;
}

/* Ensure the '+' sign aligns properly */
.change-value::before {
  content: attr(data-prefix);
  margin-right: 4px;
}
.new-header {
  color: #0F8FE0 !important;              /* White text */
  font-weight: bold;
}

/* Example Usage in HTML:
<span class="positive-change change-value" data-prefix="+">5.00</span>
<span class="negative-change change-value" data-prefix="-">-3.00</span>
<span class="no-change new-entry">0.00</span>
*/
