//
// Bootstrap Daterangepicker plugin customization: https://www.daterangepicker.com
//

$foreground: rgb(26,35,63); // white color for foreground
$background: rgb(20,27,47); // dark blue color for background
$selection-blue: rgb(62,151,255);

// Base
.daterangepicker {
  padding: 0;
  margin: 0;
  border: 0;
  width: auto;
  background-color: rgb(20,27,47)!important;
  box-shadow: var(--#{$prefix}dropdown-box-shadow);
  font-family: $font-family-sans-serif;
  z-index: $zindex-dropdown;
  @include border-radius($dropdown-border-radius);

  &:after,
  &:before {
    display: none;
  }

  td {

    &.off,
    &.off.in-range,
    &.off.start-date,
    &.off.end-date {
      background-color: $foreground!important;
    }
  }

  .modal-open & {
    z-index: $zindex-modal + 1;
  }

  //calendar color
  .calendar-table {
    background-color: rgb(26,35,63)!important;
    border: 1px solid var(--#{$prefix}gray-200)!important;
  }

  //left side range selector
  .ranges {
    @include border-radius($dropdown-border-radius);
    background-color: $foreground;
    position: relative;
    overflow: hidden;

    ul {
      padding: 1rem 0;
      width: $daterangepicker-ranges-list-width;
      overflow: auto;
      max-height: 350px;
    }

    li {
      padding: 0.7rem 1.75rem;
      font-weight: 500;
      font-size: 1rem;
      color: EBF4F8;
      transition: $transition-link;

      &:hover {
        background-color: var(--#{$prefix}component-hover-bg);
        color: var(--#{$prefix}component-hover-color);
        transition: $transition-link;
      }

      &.active {
        background-color: var(--#{$prefix}component-active-bg);
        color: white;
        transition: $transition-link;
      }
    }
  }

  &.show-calendar {
    .ranges {
      @include border-radius(0);
      @include border-top-start-radius($dropdown-border-radius);
      margin-top: 0;
      height: 310px;
    }
  }

  &.show-ranges {
    &.show-calendar {
      .ranges {
        border-right: 1px solid var(--#{$prefix}gray-200);
      }
    }

    .drp-calendar.left {
      border-left: 0;
    }
  }

  .drp-buttons {
    padding: 1rem 1.75rem;
    border-top: 0.2px solid var(--#{$prefix}gray-200)!important;

    .btn {
      font-size: 1rem;
      font-weight: $font-weight-semibold;
      padding: 0.5rem 1rem;
    }

    //cancel button
    .cancelBtn {
      @include button-custom-variant(
        $color: white,
        $icon-color: var(--#{$prefix}light-inverse),
        $border-color: var(--#{$prefix}light),
        $bg-color: var(--#{$prefix}light),
        $color-active: var(--#{$prefix}light-inverse),
        $icon-color-active: var(--#{$prefix}light-inverse),
        $border-color-active: var(--#{$prefix}light-active),
        $bg-color-active: var(--#{$prefix}light-active)
      );
    }
  }

  .drp-selected {
    font-size: 0.9rem;
  }

  .drp-calendar {
    &.left,
    &.right {
      padding: 1rem 1rem;
    }

    &.left {
      border-left: 0 !important;
    }

    th,
    td {
      font-size: 1rem;
      font-weight: $font-weight-normal;
      width: 33px;
      height: 33px;
      
      //hover over days
      &.available:hover {
        @include border-radius($border-radius);
        background-color: $selection-blue;
        color: white;
      }
    }

    th {
      font-weight: $font-weight-semibold;
      color: white;

      &.month {
        font-weight: $font-weight-semibold;
        color: white;
      }

      &.next,
      &.prev {
        span {
          border-width: 0 1px 1px 0;
          border-color: var(--#{$prefix}gray-900);
        }

        &.available:hover {
          span {
            border-color: var(--#{$prefix}component-hover-color);
            border-color: white!important;
          }
        }
      }

      &.next {
        span {
          margin-right: 1px;
        }
      }

      &.prev {
        span {
          margin-left: 1px;
        }
      }
    }

    td {
      color: rgb(230, 230, 230);

      &.available.off {
        color: var(--#{$prefix}gray-400);
      }

      &.active {
        background-color: var(--#{$prefix}component-active-bg) !important;
        color: var(--#{$prefix}component-active-color) !important;
        @include border-radius($border-radius);

        &.start-date {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &.end-date {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &.start-date.end-date {
          @include border-radius($border-radius);
        }
      }

      &.today,
      &.today.active {
        background: rgb(255, 52, 1) !important;
        color: white !important;
        @include border-radius($border-radius);
      }

      //color between range
      &.in-range.available:not(.active):not(.off):not(.today) {
        background-color: rgb(232, 250, 255);
        color: black;
      }

      &:hover {
        background-color: var(--#{$prefix}component-hover-bg);
        color: var(--#{$prefix}component-hover-color);
      }
    }
  }

  select.ampmselect,
  select.minuteselect,
  select.hourselect,
  select.monthselect,
  select.yearselect {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    @include border-radius($border-radius);
    background-color: var(--#{$prefix}body-bg) !important;
    border-color: transparent;
    color: var(--#{$prefix}input-color);
    font-weight: $font-weight-semibold;
    outline: 0 !important;

    &:focus {
      background-color: var(--#{$prefix}gray-100);
    }
  }
}

// Tablet mode
@include media-breakpoint-down(md) {
  .daterangepicker {
    &.show-calendar {
      .ranges {
        float: none !important;
        height: auto !important;

        ul {
          width: 100%;
        }
      }

      .drp-calendar {
        float: none !important;
        max-width: unset !important;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
