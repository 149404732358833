/* src/components/InvoiceProcessorWrapper.css */
  
/* Fade-in and Fade-out Animations */
@keyframes fadein {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeout {
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-20px); }
}

/* Smooth Progress Bar Transition */
.smooth-progress-bar {
  transition: width 2s ease-in-out;
}

/* Optional: Customize the progress bar appearance */
.progress-bar {
  background-color: rgb(219, 223, 233); 
}

.toast .toast-message {
  font-size: 12px;
}
.form-check-inline {
  margin-right: 1rem;
}

.address-forms .form-check-label {
  line-height: 1 !important; margin-left: 2px !important; 
}
.address-forms  .form-check-input {
  height: 1.5rem; width: 1.5rem; 
}
.address-forms .form-check.form-check-inline {
  display: flex; align-items: center; gap: 5px; 
}

/* Remove hover effect on buttons with class 'no-hover' */
.no-hover:hover,
.no-hover:focus,
.no-hover:active {
  background-color: transparent !important;
  color: var(--bs-btn-color) !important;
  text-decoration: none !important;
  box-shadow: none !important;
}

.dropzone-area {
  border: 2px dashed #cccccc;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  transition: all 0.3s ease;
}

.dropzone-area.active {
  border-color: #4CAF50;
  background-color: rgba(76, 175, 80, 0.1);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  padding: 10px;
}

.image-preview-item {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}

.remove-image-btn {
  position: absolute;
  top: 2px;
  right: 2px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.remove-image-btn:hover {
  background: rgba(0, 0, 0, 0.7);
}