/* Main container */
.product-images-reviewer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100vh - 100px);
}

/* Main section layout */
.main-section {
  border-radius: 6px;
  padding: 1.5rem;
  margin-bottom: 1rem;
}

/* Grid layout for main section */
.main-section .grid {
  display: flex;
  gap: 2rem;
}

/* Input controls section */
.input-row {
  flex: 0 0 auto;
  width: 300px;
}

/* Preview panel styles */
.preview-panel {
  flex: 1;  /* Take up remaining horizontal space */
  min-width: 0;
  height: 600px; /* Fixed height */
  border-radius: 12px;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.preview-panel-header {
  padding: 16px 24px;
  font-weight: 600;
  color: #495057;
  margin-bottom: 1px;
}

.preview-container {
  width: 100%;
  flex: 1; /* Take remaining vertical space */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: #1e1e1e;
  border-radius: 0 0 12px 12px;
  overflow: auto; /* Add scroll if content exceeds container */
}

.no-image-selected {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.single-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  max-height: calc(100% - 40px);
  margin: 0 auto;
  background: transparent; /* Change from white to transparent */
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
}

/* Thumbnail grid styles */
.thumbnails-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Image container styles */
.single-image-container,
.thumbnail-container {
  position: relative;
  display: inline-block;
  height: 80%;
}

.thumbnail-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  background: white;
  margin-top: 10px;
}

.thumbnail-image:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

/* Remove button styles */
.remove-button {
  position: absolute !important;
  top: -10px;
  right: -10px;
  z-index: 10;
  width: 2rem !important;
  height: 2rem !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.remove-button .p-button-icon {
  font-size: 0.8rem;
}

/* Button container styles */
.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

/* Images table section styles */
.images-table-section .flex.justify-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  gap: 0.5rem;
}

.images-table-section .p-button-outlined {
  min-width: 150px;
}

/* Metadata display styles */
.metadata-display {
  font-size: 0.9rem;
  padding: 1rem;
  background-color: var(--surface-card);
  border-radius: 6px;
  margin-bottom: 1rem;
}

.metadata-display .grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
}

.metadata-display .col-12 {
  padding: 0.5rem;
}

.metadata-display label {
  color: var(--text-color-secondary);
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.metadata-display span {
  color: var(--text-color);
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1200px) {
  .preview-panel {
    flex: 0 0 600px;
    width: 600px;
  }
}


/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .main-section .grid {
    flex-direction: column;
  }

  .input-row {
    width: 100%;
  }

  .preview-panel {
    flex: none;
    width: 100%;
    margin-top: 1rem;
  }

  .metadata-display .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* PrimeReact overrides */
.p-float-label {
  margin-bottom: 1.5rem;
}

.product-images-reviewer .p-inputtext,
.product-images-reviewer .p-dropdown {
  width: 100%;
}

.p-tabview .p-tabview-panels {
  padding: 0rem 0 2rem;
}

.p-datatable thead.p-datatable-thead > tr .p-column-header-content {
  padding-bottom: 5px; padding-top: 5px; 
}


/* Add these styles to your CSS file */

.review-dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--surface-section);
  border-top: 1px solid var(--surface-border);
}

.checkbox-group {
  display: flex;
  gap: 2rem;
}

.checkbox-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkbox-item label {
  color: var(--text-color);
  font-weight: 500;
}

.button-group {
  display: flex;
  gap: 1rem;
}

.cancel-button {
  background-color: transparent !important;
  border: 1px solid var(--surface-border) !important;
  color: var(--text-color) !important;
}

.cancel-button:hover {
  background-color: var(--surface-hover) !important;
}

.apply-button {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color-text) !important;
}

.apply-button:hover {
  background-color: var(--primary-600) !important;
  border-color: var(--primary-600) !important;
}

/* Style for disabled state */
.cancel-button:disabled,
.apply-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Checkbox custom styling */
.p-checkbox .p-checkbox-box {
  border-radius: 4px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}


.duplicate-comparison .card {
  background: var(--surface-card);
  padding: 1.5rem;
  border-radius: 6px;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 
              0 1px 1px 0 rgba(0,0,0,0.14), 
              0 1px 3px 0 rgba(0,0,0,0.12);
}

.duplicate-comparison .image-preview {
  background: var(--surface-ground);
  border-radius: 6px;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.duplicate-comparison .image-details {
  background: var(--surface-section);
}



/* Search Dialog Styling */
.p-dialog .p-inputgroup {
  height: 100%;
}

.p-dialog .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-dialog .p-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Dropdown Styling */
.p-dropdown {
  transition: background-color 0.2s, border-color 0.2s;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: var(--primary-color);
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 1px var(--primary-color);
  border-color: var(--primary-color);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.75rem 1.25rem;
  margin: 0;
  border: 0 none;
  color: var(--text-color);
  background: transparent;
  transition: background-color 0.2s, color 0.2s;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover {
  background-color: var(--surface-hover);
  color: var(--text-color);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}


/* Search Dialog Styling */
.search-dialog {
  padding: 1rem;
}

.search-dialog .search-header {
  margin-bottom: 1.5rem;
  color: var(--text-color-secondary);
  line-height: 1.5;
  font-size: 0.875rem;
  background: var(--surface-ground);
  padding: 1rem;
  border-radius: 6px;
  border-left: 4px solid var(--primary-color);
}

.search-dialog .search-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.search-dialog .search-row {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.search-dialog .search-input-wrapper {
  flex: 2;
}

.search-dialog .filter-wrapper {
  flex: 1;
}

.search-dialog .p-inputtext,
.search-dialog .p-dropdown {
  background: var(--surface-ground);
  border: 2px solid transparent;
  transition: all 0.2s ease;
  height: 3rem;
}

.search-dialog .p-inputtext:hover,
.search-dialog .p-dropdown:hover {
  background: var(--surface-hover);
}

.search-dialog .p-inputtext:focus,
.search-dialog .p-dropdown.p-focus {
  background: var(--surface-ground);
  border-color: var(--primary-color);
  box-shadow: var(--primary-color);
}

.search-dialog .search-button {
  height: 3rem;
  background: var(--primary-color);
  border: none;
  transition: all 0.2s ease;
}

.search-dialog .search-button:hover {
  background: var(--primary-600);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-dialog .search-button:active {
  transform: translateY(0);
  box-shadow: none;
}

.search-dialog .search-button .p-button-icon {
  font-size: 1.2rem;
}

/* Dropdown Label Styling */
.p-dropdown-label.p-inputtext {
  color: var(--text-color);
  background: transparent;
}

.search-dialog .p-dropdown .p-dropdown-label {
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  color: var(--text-color);
  background: transparent;
}

/* When dropdown is focused/active */
.search-dialog .p-dropdown.p-focus .p-dropdown-label,
.search-dialog .p-dropdown:hover .p-dropdown-label {
  color: var(--text-color);
  background: transparent;
}

/* For the placeholder text */
.search-dialog .p-dropdown .p-dropdown-label.p-placeholder {
  color: var(--text-color-secondary);
}
