.format-selection-dialog .format-option {
  background: var(--surface-card);
  border: 1px solid var(--surface-border);
  transition: all 0.2s;
}

.format-selection-dialog .format-option:hover {
  background: var(--surface-hover);
  border-color: var(--primary-color);
}

.format-selection-dialog .format-option.selected {
  background: var(--primary-color-50);
  border-color: var(--primary-color);
}

.format-selection-dialog .format-radio {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--surface-ground);
  display: flex;
  align-items: center;
  justify-content: center;
}

.format-selection-dialog .format-check {
  opacity: 0.6;
}

.format-selection-dialog .format-option.selected .format-check {
  opacity: 1;
}